import "common/src/lazy_load_images.js"
import {} from "jquery-ujs"

import jQueryExtensions from "common/src/jqueryExtensions"
import MessageNavigation from "common/components/chat/MessageNavigation"
import React from "react"
import ReactDOM from "react-dom"
// import Wizard from "common/components/job_wizard/Wizard"
import PricingSurvey from "tailwind/common/components/business/PricingSurvey"

import "../../jquery-plugins/lazyload.js"
import "tailwind/common/src/menuLinks"

import "flowbite"
import "jquery-ui/ui/widgets/autocomplete"

import "hero-icon-js/hero-icon-outline"
import "hero-icon-js/hero-icon-solid"

import "velocity-animate"

// TODO: Job Wizard needs to be rewritten using Tailwind CSS.
//       Temporarily, the 'Get Quotes' link in the nav header uses the direct new job page/URL.
document.addEventListener("DOMContentLoaded", () => {
  // const jobWizard = document.getElementById('job_wizard')

  // if (jobWizard !== null ) {
  //   const jobWizardData = JSON.parse(jobWizard.getAttribute('data').replace(/&quot;/g,'"'))
  //   ReactDOM.render(<Wizard {...jobWizardData}/>, jobWizard)
  // }

  const pricingSurvey = document.getElementById('pricing-survey-container')

  if (pricingSurvey !== null) {
    const pricingSurveyData = JSON.parse(pricingSurvey.getAttribute("data").replace(/&quot;/g,'"'))
    ReactDOM.render(<PricingSurvey {...pricingSurveyData}/>, pricingSurvey)
  }

  const unseenConversationNodes = document.getElementsByClassName("unseen_conversation_count")
  Array.prototype.forEach.call(unseenConversationNodes, unseenConversationNode => {
    if (unseenConversationNode != null) {
      const unseenConversationData = JSON.parse(unseenConversationNode.getAttribute("data").replace(/&quot;/g, '"'))
      ReactDOM.render( < MessageNavigation {...unseenConversationData}/ > , unseenConversationNode)
    }
  })
})
